import React from 'react'

const DeleteModal = () => {
    (
    <>
       <h2>shivakare</h2>
    </>
  )
}

export default DeleteModal