import React from 'react'

const Home = () => {
    return (
        <>
            <h1>Home</h1>
        </>
    )
}

export default Home